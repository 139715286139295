<template>
    <div class="image-search-home">
        <a-spin :spinning="loading || moreLoading">
            <!-- <div class="menu-nav">
            <div>
                <a-icon type="setting" class="menu-nav-icon"></a-icon>
                <span class="menu-nav-text">图像检索</span>
            </div>
            </div> -->
            <div class="table-search">
                 <a-form-model :model="queryParams" ref="queryForm" size="small" layout="inline" label-width="68px">
                    <a-form-model-item label="样品编号" prop="fileName">
                        <!-- <a-input v-model="queryParams.fileName" style="width:200px;" placeholder="请输入文件名称" allowClear/> -->
                        <a-select v-model="queryParams.fileName" style="width:200px;" placeholder="请选择" :maxTagCount="1" allowClear show-search>
                            <a-select-option v-for="item in selectFiles" :key="item.name" :value="item.name">{{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-button icon="search" type="primary" v-hasPermi="['imagesearch_search']"  @click="onSearchImage" style="margin-right: 5px;">查询</a-button>
                    </a-form-model-item>
                    <a-form-model-item style="float: right;margin-right: 0px;">
                        <a-upload @change="e => handleFileChange(e)" :showUploadList="false"
                        :before-upload="beforeUpload">
                            <a-button icon="upload" type="primary" v-hasPermi="['imagesearch_upload']">上传图片</a-button>
                        </a-upload>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <a-row class="show-image" v-if="isShow">
                <a-col class="gutter-row" :span="8">
                    <img :src="leftImage.imageCode" class="left-image" v-if="leftImage.imageCode" @click="searchMoreImgById"/>
                </a-col>
                <a-col class="gutter-row right-image" :span="16">
                    <image-list ref="eleImageList" :image-files="imageFiles" :full-width="dialogWidth" :allow-click-image="false" @imageClick="imageClick" :size="2"/>
                </a-col>
            </a-row>
            <div class="image-main">
                <div class="attach-list">
                    <table>
                        <tr v-if="currentSimilar.id">
                            <td>相似度</td>
                            <td>{{currentSimilar.similar}}</td>
                            <td></td>
                        </tr>
                        <tr v-for="item in attachList" :key="item.id">
                            <td>{{item.feature}}</td>
                            <td>{{item.name}}</td>
                            <td><a-button size="small" type="primary" @click="download(item)" v-if="item.name && item.path">附件下载</a-button></td>
                        </tr>
                    </table>
                    <!-- <div class="a-single" v-if="currentSimilar.id">
                        <span class="a-s-feature">相似度：</span>
                        <span class="a-s-name">{{currentSimilar.similar}}</span>
                    </div>
                    <div class="a-single" v-for="item in attachList" :key="item.id">
                        <span class="a-s-feature">{{item.feature}}：</span>
                        <span class="a-s-name">{{item.name}}</span>

                    </div> -->
                </div>
            </div>
        </a-spin>
    </div>

</template>

<script>
import { message, Modal } from 'ant-design-vue';
import {
  imageQuery,
  imageCheck,
  getImageCheckResult,
  getAttachWithImageId,
} from "../api/knowledge/search.js";
import { optionImageNameList } from "../api/image/database.js";
import ImageList from './ImageListNotReport';
import { baseUrl } from '../utils/request';

export default {
  name: "ImageSearchHome",
  components: {
    ImageList
  },
  data() {
    return {
        loading: false,
        moreLoading: false,
        queryParams: {},
        leftImage: {},
        imageFiles: [],
        similarImageList: [],
        attachList: [],
        dialogWidth: 900,
        btnDisabled: false,
        currentSimilar: {},
        isShow: false,
        selectFiles: [],
    }
  },
//   watch:{
//     $route:{
//       handler(val,oldval){
//         this.fileName = '';
//       },
//       // 深度观察监听
//       deep: true
//     }
//   },
  created() {
    const {id, imageName} = this.$route.query;
    this.queryParams = {
        ...this.queryParams,
        fileName: imageName,
    };
    if(imageName) {
        this.onSearchImage();
    }
    if(id) {
        this.queryMoreImgList(id);
    }
    this.optionImageNameList();
  },
  methods: {
    // 获取所有图片名称
    optionImageNameList() {
        optionImageNameList().then(res => {
            if(res.success && res.data) {
                this.selectFiles = res.data || [];
            }
        });
    },
      // 原生图片搜索请求
    onSearchImage() {
        if(!this.queryParams.fileName) {
           message.error('请输入文件名称!');
           return;
        }
        this.loading = true;
        imageQuery({ name: this.queryParams.fileName }).then(res => {
            this.loading = false;
            if(res.success && res.data) {
                this.leftImage = {
                    ...res.data,
                    imageCode: 'data:image/jpeg;base64,' + res.data.imageCode
                };
                this.isShow = true;
            }
        });
    },
    // 原生图片搜索请求
    searchMoreImgById() {
        if(this.btnDisabled) {
            message.error('您点击频繁，如需重新检索，请5秒以后再次点击！');
            return;
        }
        this.btnDisabled = true;
        this.loading = true;
        const formData = new FormData();
        if(this.leftImage.id) {
            formData.append("id", this.leftImage.id);
        }else if(this.leftImage.image) {
            formData.append("image", this.leftImage.image);
        }
        imageCheck(formData).then(res => {
            this.loading = false;
            if(res.success) {
                Modal.info({
                    title:"提示信息",
                    content: "操作成功，相似搜索已加入队列，请到“检索进度”模块查看进度状况。",
                    centered:true,
                });
                setTimeout(() => {
                    this.btnDisabled = false;
                }, 5000);
            }else{
                this.btnDisabled = false;
            }
        });
    },
    // 相似图片检索结果
    queryMoreImgList(id) {
        this.moreLoading = true;
        getImageCheckResult(id).then(res => {
            this.moreLoading = false;
            if(res.success) {
                this.isShow = true;
                this.similarImageList = res.data;
                this.imageFiles = res.data.map(ele => {
                    const similarImage = ele.similarImage;
                    return {
                        ...similarImage,
                        similar: ele.similar ? Number(ele.similar).toFixed(4) : '',
                        url: 'data:image/jpeg;base64,' + similarImage?.imageCode
                    };
                });
            }
        });
    },
    // 相似图片附件
    imageClick(item) {
        this.loading = true;
        this.currentSimilar = { ...item };
        getAttachWithImageId(item.id).then(res => {
            this.loading = false;
            if(res.success) {
                this.attachList = res.data.attachList;
            }
        });
    },
    // 下载附件
    download(item) {
        window.open(baseUrl + '/api/ele/knowledge/database/download/file/'+item.id);
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('只能上传图片');
      }
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        message.error('文件不能大于20M!');
      }
      return isJpgOrPng && isLt2M;
    },
    handleFileChange(e) {
        if(!e.file?.originFileObj) {
            return;
        }
        const that = this;
        let reader = new FileReader();
        reader.vue = this;
        reader.readAsDataURL(e.file.originFileObj);
        reader.onload = function () {
            that.leftImage = {
                imageCode: this.result,
                image: e.file.originFileObj
            };
            that.isShow = true;
        }
    },
  }
}
</script>

<style scoped lang="less">
    .show-image {
        height: 300px;
        margin: 8px;
        box-shadow: 0px 1px 12px 0px rgba(61,74,148,0.5);
        border-radius: 4px;
    }

    .left-image {
        padding: 8px;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .right-iamge {
        padding: 8px;
    }

    .image-main {
        display: flex;
        justify-content: center;
        padding-top: 35px;
        font-size: 18px;
    }

    .a-single {
        margin-top: 10px;
    }

    .a-s-feature {
        font: bold;
        margin-right: 5px;
    }

    .a-s-name {
        margin-right: 5px;
    }

    .attach-list {
        width: 100%;

        table {
            border-top: 1px solid #cccccc;
            border-left: 1px solid #cccccc;
            width: 80%;
            margin: 0px auto;

            td {
                border-right: 1px solid #cccccc;
                border-bottom: 1px solid #cccccc;
                padding: 8px 12px;
            }
        }
    }

    ::v-deep .right-image .image_container_hidden {
        display: flex;
        justify-content: space-around;
        height: 100%;
    }

    ::v-deep .right-image .image_container_hidden .image_div {
        width: auto;
        height: 100%;
        padding: 0 5px;
    }

    ::v-deep .right-image .image_container_hidden .image_div img {
        width: 100%;
        height: 100%;
    }

    ::v-deep .show-image .ant-col{
        height: 100%;
        // border: 1px lightgrey solid;
    }

    ::v-deep .show-image .ant-col:first-child {
        border-right: none;
    }

    .image-search-home .main_hidden {
        height: 100%;
        background: none;
        border: none;
    }

    ::v-deep .main_hidden i {
        background: none;
    }
</style>
