import request from "../../utils/request";

// 原生图片搜索请求
export function imageQuery(query) {
  return request({
    url: '/api/ele/knowledge/database/iamge/query',
    method: 'get',
    params: query
  })
}

// 原生图片相似度检索请求
export function imageCheck(data) {
  return request({
    url: '/api/ele/knowledge/database/iamge/check',
    method: 'post',
    data: data
  })
}

// 相似图片检索结果
export function getImageCheckResult(id) {
  return request({
    url: '/api/ele/knowledge/database/getImageCheckResult/' + id,
    method: 'get',
  })
}

// 相似图片附件
export function getAttachWithImageId(id) {
  return request({
    url: '/api/ele/knowledge/database/getAttachWithImageId/' + id,
    method: 'get',
  })
}

